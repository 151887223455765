import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import theme from './theme';

/**
 * Creates a wrapper theme for Material UI and Styled Components
 * theme providers. Theme provider from styled components is used here so 
 * theme values can be accessed inside of styled components
 * https://material-ui.com/guides/interoperability/#styled-components
 * @param {Object} children - children components to wrap in the theme 
 */
const ThemeWrapper = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeWrapper;
