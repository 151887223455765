import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#236c1d',
      secondary: '#42444C'
    },
  },
});

export default theme;