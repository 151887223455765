import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WorkoutCalendar } from '../components/WorkoutCalendar';
import { WorkoutsTable } from '../../workouts/components/WorkoutsTable';
import { getWorkouts } from '../../workouts/services/workouts-service';
import { UserActionBar } from '../components/UserActionBar';
import { UserDetail } from '../components/UserDetail';

const Container = styled.div`
  margin: 15px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: auto auto auto auto;
`;

const UserActionBarContainer = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 3;
  margin: 15px;
`;

const WorkoutCalendarContainer = styled.div`
  margin: 15px;
  padding: 15px;
  grid-column: 2 / span 3;
  grid-row: 1 / span 1;
`;

const WorkoutTableContainer = styled.div`
  margin: 15px;
  grid-column: 1 / span 3;
  grid-row: 2 / span 3;
`;

const UserPage = ({ match }) => {
  const [workouts, setWorkouts] = useState([])
  const user = {
    email: match.params.id
  }

  useEffect(() => {
    const id = match.params.id;
    async function fetchData() {
      const workouts = await getWorkouts(id);
      setWorkouts(workouts)
    }

    fetchData();
  }, []);

  const updateUserRoles = (roles) => {
    console.log('TODO');
  };

  return (
    <Container>
      <UserActionBarContainer>
        <UserActionBar
          userId={user.id}
          roles={[]}
          setRoles={updateUserRoles}
        />
        <UserDetail 
          user={user}
          match={match} />
      </UserActionBarContainer>
     
      <WorkoutCalendarContainer>
        <WorkoutCalendar workouts={workouts} />
      </WorkoutCalendarContainer>
       
      <WorkoutTableContainer>
        <WorkoutsTable user={user} match={match} />
      </WorkoutTableContainer>
    </Container>
    );
};

export default UserPage;
