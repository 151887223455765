import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { WorkoutsTable } from '../components/WorkoutsTable';

const WorkoutsPageOverview = (props) => {
  const { user } = useAuth0()

  return (
    <>
      <WorkoutsTable user={user}/>
    </>
  )
};

export default WorkoutsPageOverview;
