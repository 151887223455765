import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const Container = styled.div`
  margin: 15px;
`;
const CareersPage = () => {
  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        Body Hack Careers
      </Typography>
      <Typography variant="h5" gutterBottom>
        Software Hacker
      </Typography>
      <Typography variant="h5" gutterBottom>
        DevOps Hacker
      </Typography>
      <Typography variant="h5" gutterBottom>
        Sales Hacker
      </Typography>
      <Typography variant="h5" gutterBottom>
        Marketing Hacker
      </Typography>
    </Container>
  );
};

export default CareersPage;