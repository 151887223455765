const ACCESS_TOKEN_KEY = 'accessToken'

export const retrieveToken = () => {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY);
}

export const storeToken = (accessToken) => {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export const removeToken = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
}