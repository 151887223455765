import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from "@auth0/auth0-react";
import styled from 'styled-components';
import { TextField, IconButton } from '@material-ui/core';
import Check from '@material-ui/icons/Check';

const StyledTypography = styled(Typography)`
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  padding: 5px;
`;

export const UserDetail = props => {
  const [trainerCode, setTrainerCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { user, isAuthenticated, isLoading: auth0IsLoading } = useAuth0();

  console.log({ user, isAuthenticated, auth0IsLoading});
  const handleTrainerCodeClicked = async () => {
    try {
      alert('Not implemented')
    } catch(e) {
      alert('Failed to update code', e.message);
    } finally {
      setIsLoading(false);
    }

  }
  const onTrainerCodeChanged = (value) => {
    setTrainerCode(value);
  }

  useEffect(() => {
    const { personalTrainerCode } = props.user;
    if(personalTrainerCode) {
      setTrainerCode(personalTrainerCode);
    }
  }, [props.user]);

  return (
    <Card>
       <CardContent>
          <StyledTypography variant="h5">
            Name: 
          </StyledTypography>
          <Typography variant="h5" color="primary">
            {props.match.params.id}
          </Typography>
            
          <StyledTypography gutterBottom variant="h5" >
            Personal Trainer Code:
          </StyledTypography>
          {/* TODO: Example of RBAC - letting admins edit the personal trainer code */}
          {([].includes('Admin')) ? (
            <>
            <TextField 
              disabled={isLoading}
              value={trainerCode}
              onChange={({ currentTarget }) => {
                onTrainerCodeChanged(currentTarget.value);
              }}
            />
            <IconButton onClick={handleTrainerCodeClicked}>
              <Check></Check>
            </IconButton>
            </>
          ) : (
            <Typography variant="h5">
            {props.user.personalTrainerCode}
          </Typography>
          )}
          <StyledTypography gutterBottom variant="h5">
            Email:
          </StyledTypography>
          <Typography variant="h5" color="primary" >
            {props.user.email}
          </Typography>
        </CardContent>
    </Card>
  );
};
