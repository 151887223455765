import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Auth0Provider } from "@auth0/auth0-react";
import ThemeWrapper from "./theme/theme-wrapper";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import ManageUsersPage from "./users/pages/ManageUsersPage";
import NavBar from "./common/components/NavBar";
import PrivacyPolicy from "./common/pages/PrivacyPolicy";
import history from "./history";
import UserPage from "./users/pages/UserPage";
import NoRouteMatch from "./common/pages/NoRouteMatch";
import PrivateRoute from "./common/components/PrivateRoute";
import HelpPage from "./common/pages/HelpPage";
import CareersPage from "./common/pages/Careers";
import WorkoutPageDetail from "./workouts/pages/WorkoutPageDetail";
import WorkoutsPageOverview from "./workouts/pages/WorkoutsPageOverview";
import {
  UserContext,
  UserContextProvider,
} from "./common/services/user-context";

function App() {
  const state = useContext(UserContext);

  return (
    <Auth0Provider
      domain="body-hack.us.auth0.com"
      clientId="1aDrKE2unYef6hiQyGeU9cze0xYz3ST4"
      redirectUri={window.location.origin}
      audience="https://body-hack-api.com/"
      cacheLocation='localstorage'
    >
      <Router history={history}>
        <UserContextProvider value={state}>
          <ThemeWrapper>
            <>
              <CssBaseline />
              <div className="App">
                <NavBar />
                <Switch>
                  <PrivateRoute path="/" exact component={ManageUsersPage} />
                  <PrivateRoute path="/users/:id" component={UserPage} />
                  <PrivateRoute
                    path="/workouts"
                    exact
                    component={WorkoutsPageOverview}
                  />
                  <PrivateRoute
                    path="/workout/:id"
                    exact
                    component={WorkoutPageDetail}
                  />
                  <Route path="/privacy" exact component={PrivacyPolicy} />
                  <Route path="/help" exact component={HelpPage} />
                  <Route path="/careers" exact component={CareersPage} />

                  <Route component={NoRouteMatch} />
                </Switch>
              </div>
            </>
          </ThemeWrapper>
        </UserContextProvider>
      </Router>
    </Auth0Provider>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
