import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import Redirect from 'react-router-dom/Redirect';
import { getUsers } from '../services/users-service'

export const UsersTable = () => {
  const [users, setUsers] = useState([])
  const [toUserPage, setToUserPage] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState("")


  useEffect(() => {
    async function fetchData() {
      const userResponse = await getUsers();
      setUsers(userResponse)
    }
    fetchData()
  }, [])

  const columns = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
  ];


  const onUserRowClicked = (event, rowData) => {
    setSelectedUserId(rowData.email)
    setToUserPage(true)
  };

  if (toUserPage) {
    return <Redirect to={`/users/${selectedUserId}`} />;
  }
  return (
    <MaterialTable
      title="Users"
      columns={columns}
      data={users}
      onRowClick={onUserRowClicked}
      options={{
        paging: false,
      }}
    />
  );
}
