import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import '../../main.scss' // webpack must be configured to do this

export const WorkoutCalendar = (props) => {
  const [formattedCompletedWorkouts, setFormattedCompletedWorkouts] = useState([])
  
  const handleDateClick = (arg) => {
    alert(arg.dateStr);
  }

  useEffect(() => {
    const { workouts } = props;
    setFormattedCompletedWorkouts(workouts.map((workout) => {
      return {
        title: workout.status==='COMPLETE' ? "COMPLETED":"TODO" ,
        date: workout.date,
        color: workout.status=== 'COMPLETE' ? '#378006' : '#778899' 
      }
    }))
  }, [props.workouts]);

  return (
    <FullCalendar 
      defaultView="dayGridMonth" 
      plugins={[ dayGridPlugin, interactionPlugin ]}
      dateClick={handleDateClick}
      events={formattedCompletedWorkouts} 
      />
  );
}
