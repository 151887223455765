import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 15px;
`;
const PrivacyPolicy = () => {
  return (
    <Container>
      <h1>Privacy Policy</h1>
      <p>
        This policy applies to all information collected or submitted on this
        website and our apps for iOS and Android.
        <h2>Information we collect</h2>
        New Body Hack accounts are created with your name, email, workout goals
        and preferences. Email addresses are only used for logging in, and
        communication about your workout routines or other support requests you
        initiate.
        <br />
        <br />
        We store information about your workouts and exercises, and sync this
        information between the website and your devices. We also collect
        anonymous statistics about any mobile app crashes.
      </p>
    </Container>
  );
};

export default PrivacyPolicy;
