import React from 'react';
import { ExercisesTable } from '../../exercises/components/ExercisesTable';

const WorkoutPageDetail = (props) => {
  return (
    <>
      <ExercisesTable workoutDate={props.location.state.workoutDate}/>
    </>
  )
};

export default WorkoutPageDetail;
