import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import * as authTokenStoreService from './auth-token-store';
import { API_BASE_URL } from '../../constants';

/**
 * Axios http client instance
 */
const instance = axios.create({
  baseURL: API_BASE_URL,
});

/**
 * Sends the http request
 * @param {Object} config - Axios request config - https://github.com/axios/axios#request-config
 * @returns {Object} - Axios response object - https://github.com/axios/axios#response-schema
 */
const send = async (config) => {
  try {
    const token = authTokenStoreService.retrieveToken();
    return await instance.request({
      ...config,
      headers: {"Authorization" : `Bearer ${token}`}
    });
  } catch(error) {
    console.log(error);
    // if(error.response.status === 401) {
    //   // redirect to origin if token expired (shows the login page to the user) and delete local access token
    //   // authTokenStoreService.removeToken();
    //   // window.location.replace(window.location.origin);
    // }
    return error.response;
  }
}

/**
 * Prepares and sends a HTTP GET request
 * @param {string} endpoint - the relative URL to send the request to
 * @returns {Object} - response body content
 */
export const get = async (endpoint) => {
  const { data } = await send({
    url: endpoint,
    method: 'get',
  });
  return data;
};

/**
 * Prepares and sends a HTTP PUT request
 * @param {string} endpoint - the relative URL to send the request to
 * @param {Object} requestBody - the request body
 * @returns {Object} - response body content and http status
 */
export const put = async (endpoint, requestBody) => {
  const { data, status } = await send({
    url: endpoint, 
    method: 'put',
    data: requestBody, 
  });
  return { data, status };
};

/**
 * Prepares and sends a HTTP PATCH request
 * @param {string} endpoint - the relative URL to send the request to
 * @param {Object} requestBody - the request body
 * @returns {Object} - response body content and http status
 */
export const patch = async (endpoint, requestBody) => {
  const { data, status } = await send({
    url: endpoint, 
    method: 'patch',
    data: requestBody, 
  });
  return { data, status };
};

/**
 * Prepares and sends a HTTP POST request
 * @param {string} endpoint - the relative URL to send the request to
 * @param {Object} requestBody - the request body
 * @returns {Object} - response body content and http status
 */
export const post = async (endpoint, requestBody) => {
  const { data, status } = await send({
    url: endpoint, 
    method: 'post',
    data: requestBody, 
  });
  return { data, status };
}

/**
 * Prepares and sends a HTTP DELETE request
 * @param {string} endpoint - the relative URL to send the request to
 * @param {Object} requestBody - the request body
 * @returns {Object} - response body content and http status
 */
export const httpDelete = async (endpoint) => {
  const { data, status } = await send({
    url: endpoint, 
    method: 'delete',
  });
  return { data, status };
}
