import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider'
import Redirect from 'react-router-dom/Redirect';

const StyledCard = styled(Card)`
  margin: 15px;
`;

const NoRouteMatch = ({ location }) => {
  const [toHomePage, setToHomePage] = useState(false);

  const navigateToHomePage = () => {
    setToHomePage(true);
  }
  if (toHomePage === true) {
    return <Redirect to="/" />;
  }
  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5">
          Page not found :(        
        </Typography>
        <Typography variant="subtitle1">
          The page you are looking for could not be found.     
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Button 
          variant="contained" 
          color="inherit"
          onClick={() => navigateToHomePage()}
        >Go to homepage</Button>
      </CardActions>
    </StyledCard>
  );
};

export default NoRouteMatch;