import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { UsersTable } from '../components/UsersTable';

const ManageUsersPage = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if(isLoading && !isAuthenticated) {
    return (
      <div>Loading..</div>
    )
  }

  return (
    <>
      <UsersTable />
    </>
  )
};

export default ManageUsersPage;
