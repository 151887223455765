import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { getExercises, createExercise, deleteExercise, updateExercise } from '../services/exercise-service';
import {
  useParams
} from "react-router-dom";

export const ExercisesTable = (props) => {
  const [exercises, setExercises] = useState([])
  const { id } = useParams();

  const columns = [
    { title: "Name", field: "name" },
    { title: "Order", field: "order" },
    { title: "Sets", field: "sets" },
    { title: "Reps", field: "reps" },
    { title: "Weight", field: "weight" },
  ];
  
  useEffect(() => {
    async function fetchData() {
      const exercises = await getExercises(id)
      setExercises(exercises);
    }
    fetchData();
  }, []);
  
  const onExerciseAdd = async (exercise) => {
    try {
      const payload = {
        workout_id: id,
        name: exercise.name,
        order: exercise.order,
        sets: exercise.sets,
        reps: exercise.reps,
        weight: exercise.weight
      }
      const result = await createExercise(payload);
      if(result.status === 201) {
        setExercises([...exercises, exercise])
      } else {
        alert(`Failed to add ${result}`)
      } 
    } catch(error) {
      alert(error);
    }
  }

  const onExerciseUpdate = async (newExercise, OldExercise) => {
    try {
      console.log("newEx", newExercise);
      await updateExercise(newExercise.id, newExercise);
      console.log('existingEx', exercises)
    } catch(error) {
      alert(error);
    }
  }

  const onExerciseDelete = async (exercise) => {
    try {
      await deleteExercise(exercise.id);
      setExercises(exercises.filter(ex => ex.id != exercise.id))
    } catch(error) {
      alert(error);
    }
  }

  return (
    <>
      <MaterialTable
        title={`Exercises: ${props.workoutDate}`}
        columns={columns}
        data={exercises}
        editable={{
          onRowAdd: (newExercise) => onExerciseAdd(newExercise),
          onRowUpdate: (newExercise, oldExercise) =>
            onExerciseUpdate(newExercise, oldExercise),
          onRowDelete: (oldExercise) => onExerciseDelete(oldExercise),
        }}
      />
    </>
  );
}