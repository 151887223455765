import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
  padding: 15px;
`;

export const RoleManager = ({ roles, closeDrawer, userId, setRoles }) => {
  const [selectedRoles, setSelectedRoles] = useState({
    Admin: false,
    Trainer: false,
    Member: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserId, setUserId] = useState('');

  useEffect(() => {
    if (Object.keys(roles).length !== 0) {
      setSelectedRoles({
        Admin: roles.includes('Admin'),
        Trainer: roles.includes('Trainer'),
        Member: roles.includes('Member'),
      });
    }
    if (currentUserId) {
      setUserId(userId);
    }
  }, [roles, currentUserId]);

  const handleChange = (name) => (event) => {
    setSelectedRoles({ ...selectedRoles, [name]: event.target.checked });
  };

  const onSaveRolesClicked = async () => {
    setIsLoading(true);
    try {
      alert('not implemented!');
    } catch (err) {
      alert(err);
    }
  };
  if (Object.keys(roles).length === 0) {
    return null;
  }
  return (
    <Container>
      <h1>Manage user roles</h1>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedRoles.Admin}
                  onChange={handleChange('Admin')}
                  color="primary"
                  value="Admin"
                />
              }
              label="Admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedRoles.Trainer}
                  onChange={handleChange('Trainer')}
                  color="primary"
                  value="Trainer"
                />
              }
              label="Trainer"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedRoles.Member}
                  onChange={handleChange('Member')}
                  color="primary"
                  value="Member"
                />
              }
              label="Member"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSaveRolesClicked()}
          >
            Save Roles
          </Button>
        </>
      )}
    </Container>
  );
};
