import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useAuth0 } from "@auth0/auth0-react";
import Redirect from 'react-router-dom/Redirect';

const UserMenu = (props) => {
  const [userId, setUserId] = useState('');
  const { logout } = useAuth0();

  // Had to do this b/c this issue:
  // navigate to profile, try to navigate again, props.toProfile === true
  // so does a redirect instead of showing menu
  if(!props.open) {
    props.setProfile(false);
  }
  const handleProfile = async () => {
    alert('Not implemented!')
  }
  const handleAuth0Logout = async () => {
    logout({ returnTo: window.location.origin })
  }

  if (props.toProfile === true) {
    console.log('redirecting');
    const userPage = `/users/${userId}`;
    return <Redirect to={userPage}/>;
  }
  
  return (
    <>
      <Menu
        id="menu-appbar"
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={props.open}
        onClose={props.handleClose}
      >
        <MenuItem id={0} onClick={handleProfile}>
          My Profile
        </MenuItem>
        <MenuItem id={0} onClick={handleAuth0Logout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;