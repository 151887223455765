import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ComputerIcon from '@material-ui/icons/Computer';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import createRoutine from '../../images/create-routine.png';
import assignRoutine from '../../images/assign-routine.png';
import routineTemplateExercises from '../../images/routine-template-exercises.png';
import { Divider } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

const Container = styled.div`
  margin: 15px;
`;
const HelpPage = () => {
  return (
    <Container>
      <Typography variant="h4" component="h2" gutterBottom>
        Body Hack Help Guide
      </Typography>
      <Typography variant="body1" gutterBottom>
        Managing your workout routines or routines for your clients is done via our website. Progressing through workouts at the gym is done via the mobile application.
        <br/>
        <br /> 
        <ComputerIcon fontSize='large'/> 
        <br/> This website has everything you need to create new workout routines, assign them to yourself or your clients, and keep them up to date as they make progress at the gym.
        <br/>
        <PhoneIphoneIcon fontSize='large'/><br/> The mobile application allows you to view your workout, start workouts, track your progress, and complete workouts.  
        <br/><br/>
      </Typography>
      <Divider />
      <br/>
      <Typography variant="h4" gutterBottom>
        Creating new workout routine templates
      </Typography>
      <Typography variant="subtitle1">
        Workout routines are defined as templates with no user specific data (exercise weights are excluded for example).  A template can be shared among many users or specific to a single user.  
      </Typography>
      <br/>
      <Typography variant="body1" paragraph='true' gutterBottom>
        1. Navigate to <Link to="/workout">Workouts</Link> using the button in the upper nav bar. 
        <br/>
        2. Create a new routine by using the <AddBoxIcon/> button in the top right.
        <br/>
        3. Add a Name and Split value. Ex: A 4 day routine is ABCD, a 3 day routine is ABC, etc.
        <br/>
        4. Press save using the <CheckIcon/> button.
        <br/>
        <br/>
        <Container>
          <img src={createRoutine} alt="Create Workout Routine Example" /> 
        </Container>
        <br/>
        5. Click on the new row in the table to create exercises for the newly created routine.
        <br/>
        <br/>
        <Container>
          <img src={routineTemplateExercises} alt="Create Routine Template Exercises Example" /> 
        </Container>
        <br/>
      </Typography>
      
      <Divider/>
      <br/>
      <Typography variant="h4" gutterBottom>
        Updating workout routine templates
      </Typography>
      <Typography variant="subtitle1">
        Adding exercises can be done by modifying the template.
      </Typography>
      <Typography variant="body1" gutterBottom>      
        1. Navigate to <Link to="/workout">Workouts</Link> using the button in the upper nav bar.<br/>
        2. Click on the workout routine template. <br/>
        3. Add exercises to the template using the <AddBoxIcon/> button in the top right.  <br/>
        4. Edit or delete exercises using the <CreateIcon/> button or the <DeleteIcon /> button. <br/>
      </Typography>
      <br/>
      <Divider/>
      <br/>
      <Typography variant="h4" gutterBottom>
        Assigning a workout routine to a user
      </Typography>
      <Typography variant="body1" gutterBottom>
        1. Navigate to <Link to="/">Users</Link> using the button in the upper nav bar.<br/>
        2. Edit using the <CreateIcon/> button in the table view by clicking on it. <br/>
        3. Under Workout Name select workout routine name.   <br/>
        4. Save the routine using the <CheckIcon/> icon to the left of the table row. 
        <br/>
        <br/>
        <Container>
          <img src={assignRoutine} alt="Assign Workout Routine Example" /> 
        </Container>

      </Typography>
      <Divider />
      <br/>
      <Typography variant="h4" gutterBottom>
        Updating workout routine exercise weights
      </Typography>
      <Typography variant="subtitle1">
        Workout routines are based off of templates. Anything specific to a user is on the user page.
      </Typography>
      <Typography variant="body1" gutterBottom>      
        1. Navigate to <Link to="/">Users</Link> button in the upper nav bar.<br/>
        2. Select a user in the table view. <br/> 
        3. Edit exercises using the <CreateIcon/> button to set the exercise weight and increment (how much the exercise weight should update each workout) <br/>  
      </Typography>
      <br/>
      <Divider/>
      <br/>
      <Typography variant="h4" gutterBottom>
        Viewing workout routine stats.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1. Navigate to <Link to="/">Users</Link> page using the button in the upper nav bar.<br/>
        2. Select a user in the table view. <br/>
        3. Completed workouts are viewable in the calendar component.  <br/>
        4. Exercise stats are viewable in the chart by selecting an exercise from the drop-down list. <br/> 
        </Typography>
    </Container>
  );
};

export default HelpPage;