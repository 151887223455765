import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
    component={withAuthenticationRequired(Component, {
      onRedirecting: () => <div>loading auth...</div>,
    })}
    {...rest}
  />
  );
};

export default PrivateRoute;