import React, { useState } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import { Button } from '@material-ui/core';
import { RoleManager } from './RoleManager';
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const Container = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin: 15px;
`;

export const UserActionBar = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toUsersPage, setToUsersPage] = useState(false);
  const [message, setMessage] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [invalidPhoneNumberErrorText, setInvalidPhomeNumberErrorText] = useState('');
  
  const toggleDrawer = (open) => (event) => setIsDrawerOpen(open);

  const viewClientsClicked = () => setToUsersPage(true);

  const sendSmsClicked = () => setIsModalOpen(true);
  
  const onSendMessageClicked = () => {
    alert('not implemented!');
  }
  
  const toggleModal = () => {
    setIsModalOpen(false);
  }

  const phoneNumberChanged = (value) => {
    // only allows 0-9 inputs
    let currentValue = value.replace(/[^\d]/g, '');
    if(value.length > 1 && value.length <= 4) {
      currentValue = `(${currentValue}`;
    }
    if(value.length > 4 && value.length <= 8) {
      currentValue = `(${currentValue.slice(0,3)})${currentValue.slice(3, 8)}`;
    }
    if(value.length > 8) {
      currentValue = `(${currentValue.slice(0,3)})${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
    }
    if(value.length > 13) {
      setInvalidPhoneNumber(true);
      setInvalidPhomeNumberErrorText('Specify a valid phone number')
    }
    else {
      setInvalidPhoneNumber(false);
      setInvalidPhomeNumberErrorText('');
    }
    setPhoneNumber(currentValue);
  }
  
  if (toUsersPage === true) {
    return <Redirect to="/" />;
  }

  return (
    <Paper>
    <Container>
      <Button
          variant="contained"
          color="primary"
          onClick={() => viewClientsClicked()}
        >
          View Clients
        </Button>
        <Button
          variant='contained'
          color="primary"
          onClick={() => sendSmsClicked()}
          >
          Invite Clients
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleDrawer(true)}
        >
          Manage Roles
        </Button>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <RoleManager
            roles={props.roles}
            setRoles={props.setRoles}
            userId={props.userId}
            closeDrawer={toggleDrawer()}
          />
        </Drawer>
        <Dialog 
          open={isModalOpen} 
          onClose={toggleModal} 
          aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">Send SMS
        
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Send a welcome message to your clients phone, specify the text below.
          </DialogContentText>
          <form autoComplete="off">
            <TextField 
            autoFocus
            label="Phone Number"
            error={invalidPhoneNumber}
            value={phoneNumber}
            
            helperText={invalidPhoneNumberErrorText}
            onChange={(event) => phoneNumberChanged(event.target.value)}>
            </TextField>
            <TextField
              margin="dense"
              id="name"
              label="text message"
              type="text"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              fullWidth
            />         
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={onSendMessageClicked} color="primary">
            Send Message 
          </Button>
        </DialogActions>
      </Dialog>
      </Container>
        </Paper>
  );
};
