import React, { useContext, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import UserMenu from "./UserMenu";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";

const navButtonContainer = {
  marginLeft: "auto",
};

function NavBar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [toProfile, setProfile] = useState(false);
  
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Button component={NavLink} to="/" exact color="inherit">
            <Typography variant="h6" color="inherit">
              Body Hack
            </Typography>
          </Button>
          <div style={navButtonContainer}>
            <Button component={NavLink} to="/workouts" exact color="inherit">
              My Workouts
            </Button>
            <Button component={NavLink} to="/" exact color="inherit">
              My Clients
            </Button>
            <Button component={NavLink} to="/help" exact color="inherit">
              Help
            </Button>
            <Button component={NavLink} to="/privacy" exact color="inherit">
              Privacy Policy
            </Button>
            <IconButton
              aria-label="current user icon"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <UserMenu
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              toProfile={toProfile}
              setProfile={setProfile}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default NavBar;
