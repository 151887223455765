import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Redirect from 'react-router-dom/Redirect';
import { getWorkouts, createWorkout, deleteWorkout } from '../services/workouts-service';

export const WorkoutsTable = (props) => {
  const [workouts, setWorkouts] = useState([])
  const [toWorkoutPage, setToWorkoutPage] = useState(false);
  const [workoutId, setSelectedWorkoutId] = useState('');
  const [workoutDate, setSelectedWorkoutDate] = useState('')

  const columns = [
    { title: "Date", field: "date", type: "date", defaultSort: "desc" },
    { title: "Status", field: "status", editable: false },
  ];
  
  useEffect(() => {
    async function fetchData() {
      const workouts = await getWorkouts(props.user.email);
      setWorkouts(workouts);
    }
    fetchData();
  }, [props.user]);

  const handleWorkoutClicked = (event, workout) => {
    console.log('workout clicked!', workout)
    setToWorkoutPage(true);
    setSelectedWorkoutId(workout.id);
    setSelectedWorkoutDate(workout.date);
  }
  const getMonth = (date)=> {
    let month = date.getMonth() + 1 
    if(month < 10){
      month = "0" + month 
    }
    return month  
  }
  const onWorkoutAdd = async (workout) => {
    try { 
      const month = getMonth(workout.date)
      const day = workout.date.getDate().toString().padStart(2, '0')
      const payload = {
        user_id: props.user.email,
        date: `${workout.date.getFullYear()}-${month}-${day}`,
      }
      console.log(payload)
      const result = await createWorkout(payload);
      if(result.status === 201) {
        setWorkouts([...workouts, { ...workout, date: `${workout.date.getFullYear()}-${workout.date.getMonth() + 1}-${workout.date.getDate()}`, status: 'TODO', id: result.data.workoutId }])
      } else {
        alert(`Failed to add ${result}`)
      }
    } catch(error) {
      alert(error);
    }
  }

  const onWorkoutUpdate = async (newWorkout, oldWorkout) => {
    try {
      console.log('on workout update clicked!');
    } catch(error) {
      alert(error);
    }
  }

  const onWorkoutDelete = async (workout) => {
    try {
      console.log('on workout deleted clicked!',workout)
      await deleteWorkout(workout.id,workout.date);
      //const index = workouts.indexOf(workout);
      console.log('workouts',workouts)
      //console.log('index',index)
      setWorkouts(workouts.filter(fworkout=>fworkout.id!=workout.id))
    } catch(error) {
      alert(error);
    }
  }
  if(toWorkoutPage) {
    return <Redirect to={{pathname: `../workout/${workoutId}`, state: { workoutDate}}}/>
  }
  return (
    <MaterialTable
      title={`Workouts for: ${props.user.email}`}
      columns={columns}
      data={workouts}
      editable={{
        onRowAdd: (newWorkout) => onWorkoutAdd(newWorkout),
        onRowUpdate: (newWorkout, oldWorkout) =>
        onWorkoutUpdate(newWorkout, oldWorkout),
        onRowDelete: (oldWorkout) => onWorkoutDelete(oldWorkout),
      }}
      onRowClick={handleWorkoutClicked}
      options={{
        addRowPosition: 'first'
        }
      }
    />
  );
}