import React, { useState, useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { retrieveToken, storeToken } from './auth-token-store';

export const UserContext = React.createContext();

export const UserContextProvider = (props) => {
  const value = retrieveToken();
  const [isLoggedIn, setLoggedIn] = useState(!!value);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function fetchToken(){
      console.log('in user context')
      const token = await getAccessTokenSilently();
      console.log('uc token:', token);
      // TODO: just storing in session storage for later retrieval in api client, need to set context values for components
      storeToken(token);
      setLoggedIn(true)
    }
    fetchToken();
  }, []);

  return (
    <UserContext.Provider value={{ isLoggedIn, setLoggedIn }}>
      {props.children}
    </UserContext.Provider>
  );
};